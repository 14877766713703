var _ = require('underscore');
var ImsAuth = require('../../util/ims_auth.js');
const ImsEvents = require('../../util/ims_events.js');
const UrlService = require('../../util/url_service.js').default;

/**
 * A service used to deal with user authentication.
 * @ngInject
 */
function AuthenticationService($q, $httpParamSerializer, $timeout, $window, PostLoginActionCreatorService) {
  return {
    handleAuthenticationWithPostLoginAction: handleAuthenticationWithPostLoginAction
  };

  /**
   * If the user is already logged in, this method will resolve a promise
   * immediately without redirecting or creating a post login action.
   *
   * If the user is not already logged in, this method will create a post login
   * action on the server side and then redirect the user to the Adobe login
   * page with the post login action id in the url.
   *
   * @param {String} actionName The name of the action that should be executed after login
   * @param {Object} data An object representing the extra data needed to complete the action
   *
   * @return {Promise}
   */
  function handleAuthenticationWithPostLoginAction(actionName, data) {
    return $q(function(resolve, reject) {
      ImsEvents.addOnReadyHandler(function() {
        if (ImsAuth.isSignedInUser()) {
          resolve();
        } else {
          PostLoginActionCreatorService.createPostLoginAction(actionName, data).
            then(redirectToLoginPage).catch(reject);
        }
      });
    });
  }

  /**
   * Redirect the page to the Adobe login page, and include an encoded version
   * of the original url + the postLoginActionId as the `back_to` param.
   *
   * @param {String} postLoginActionId
   */
  function redirectToLoginPage(postLoginActionId) {
    var searchParams = $httpParamSerializer(_.extend(
      UrlService.getSearchParams(),
      PostLoginActionCreatorService.getPostLoginActionParamsForUrl(postLoginActionId)));

    $window.location = '/login/sso?back_to=' +
      encodeURIComponent($window.location.pathname + '?' + searchParams);
  }
}

module.exports = AuthenticationService;
