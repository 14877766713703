/**
 * @ngInject
 */
function I18nService($http, DataService) {
  return {
    getMessage: getMessage,
    getMessageWithCount: getMessageWithCount,
    getLocaleInfo: getLocaleInfo,
    setLocale: setLocale
  };

  /**
   * Returns the preloaded i18n message and replaces any placeholder keys.
   *
   * @private
   * @param {Object} keyValues
   * @return {String}
   */
  function getMessage(messageHash, messageId, keyValues) {
    var message = messageHash[messageId];
    if (keyValues) {
      for (var key in keyValues) {
        message = message.replace('%{' + key + '}', keyValues[key]);
      }
    }
    return message;
  }

  function getMessageWithCount(messageHash, count, keyValues) {
    var messageKey = count === 1 ? 'one' : 'other';
    return getMessage(messageHash, messageKey, keyValues);
  }

  function getLocaleInfo() {
    return DataService.get('/locale_info');
  }

  function setLocale(locale) {
    return $http.post('/set_locale', { locale: locale });
  }
}

module.exports = I18nService;
