/**
 * Utilities sending messages to Newrelic.
 */

let _pendingRequests = {};

/**
  * Report a PageAction event to NewRelic immediately.
  *
  * @param {String} name
  * @param {} attributes
  */
export function addPageAction(name, attributes) {
  // The Newrelic API is loaded through an external JS file, so make sure the
  // API present before continuing.
  if (typeof window.newrelic == 'undefined' || !window.newrelic) {
    return;
  }

  window.newrelic.addPageAction(name, attributes);
}

/**
  * Report a PageAction event to NewRelic with a de-duplication delay. This
  * waits for the specified number of milliseconds to pass before dispatching
  * the event. If an event with the same name comes in before that, the
  * older event is canceled.
  *
  * @param {String} name
  * @param {} attributes
  * @param {Number} timeoutMs
  */
export function addPageActionWithDelay(name, attributes, timeoutMs) {
  // If there was already a request made for this specific action name, cancel
  // it and make a new request.
  if (_pendingRequests[name]) {
    clearTimeout(_pendingRequests[name]);
  }

  // Wait before sending a newrelic request, so we can cancel the request if
  // another comes in before the timeout has passed.
  _pendingRequests[name] = setTimeout(function() {
    addPageAction(name, attributes);
    delete _pendingRequests[name];
  }, timeoutMs);
}

/**
 * Add listeners for analytics events from adobe-fonts-site and send them to NewRelic
 */
export function init() {
  window.addEventListener('af-analytics', function(event) {
    const actionName = ['af-analytics', event.detail.componentName, event.detail.eventName].join('.');
    const {
      componentName,
      eventName,
      ...attributes
    } = event.detail;

    addPageAction(actionName, attributes);
  });
}

/**
  * Adds a custom attribute to the PageView event via the Browser agent
  *
  * @param {String} name
  * @param {String} value
  */
export function setCustomAttribute(name, value) {
  // The Newrelic API is loaded through an external JS file, so make sure the
  // API present before continuing.
  if (!window.newrelic) {
    return;
  }

  window.newrelic.setCustomAttribute(name, value);
}

/**
 * Stringify a click with page name, custom labeling (optional), and event type (optional)
 *
 * @param {String} page
 * @param {Event} event
 * @param {String} label
 * @returns String
 */
export function getNewRelicName(page, event = {}, label = null) {
  const baseName = ['typekit', page];

  if (!event.type) {
    return label ? baseName.concat(label).join('.') : baseName.join('.');
  }

  return label ? baseName.concat(label, event.type).join('.') : baseName.concat(event.type).join('.');
}

/**
 * Get the referrer URL that led the user to the current page
 *
 * @returns String
 */
export function getReferrerHeader() {
  return window.document.referrer;
}

/**
 * Send an analytics event to New Relic with the given action name and attributes.
 * Include the location of the page when event is fired
 * with library-specific attributes if the page is a library detail page.
 * To be used for tracking events that could happen anywhere across the site, like
 * add to web project flow or favorite a font.
 *
 * @param {*} actionName String
 * @param {*} attributes Object
 */
export function sendAnalyticsEventWithPage(actionName, attributes = {}) {
  const location = window.location.pathname.replace('/', '');
  let page = location.split('/')[0];

  if (location.includes('libraries/')) {
    page = 'library-detail-page';

    const library = document.querySelector('af-library-show-page');

    if (library) {
      attributes = {
        ...attributes,
        isLibraryPublic: library.getAttribute('is-library-public')
      };
    }
  }

  addPageAction(`typekit.${page}.${actionName}`, attributes);
}

/**
 * Event handler will listen to all click events that happen on the page
 * for anchor and input elements
 *
 * @param {Object} event
 */
export function handlePageClick(event, page) {
  const targetElement = event.target;

  if (targetElement.matches('a, a *')) {
    handleAnchorClick(event, page);
  } else if (targetElement.matches('input')) {
    handleInputClick(event, page);
  }
}

/**
 * Handles clicking on links. Element's URL and class name are sent to New Relic.
 */
function handleAnchorClick(event, page) {
  const eventAnchorElement = event.target.closest('A');
  if (eventAnchorElement != null) {
    const elementUrl = eventAnchorElement.href;
    const elementClass = eventAnchorElement.className;
    addPageAction(getNewRelicName(page, {}, 'click'), {elementUrl: elementUrl, elementClass: elementClass});
  }
}

/**
 * Handles input fields. User's input is sent to New Relic.
 * Not applicable for AFS components / input elements within shadow DOM.
 */
function handleInputClick(event, page) {
  let inputField = event.target;

  if (inputField.type === 'submit') {
    const parentFormElement = event.target.closest('FORM');
    inputField = parentFormElement.querySelector('input[type=text]');
  }
  addPageAction(getNewRelicName(page, {}, 'input'), {inputValue: inputField.value, inputName: inputField.name});
}
