const newRelicUtil = require('../../util/new_relic.js');

/**
 * A service for sending messages to Newrelic.
 *
 * @deprecated Replaced by the standalone New Relic utils
 * @ngInject
 */
function NewrelicService() {
  // Pass along all of the util methods from the New Relic util
  return {
    ...newRelicUtil
  };
}

module.exports = NewrelicService;
