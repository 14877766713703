var angular = require('angular');
var DATA = {};

/**
 * This service allows you to prepopulate data using a <script> tag and then
 * retrieve that data by a key.
 *
 * The script tag must have an id that will be used to retrieve that data and it
 * must have the "text/ng-data".
 *
 * The data inside the tag is assumed to be JSON formatted.
 *
 * @example
 *
 *   <script id="my_data" type="text/ng-data">
 *     {
 *       'foo': 'blah'
 *     }
 *   </script>
 *
 * @ngInject
 */
function DataService($q) {
  return {
    get: get,
    loadDataFromTemplate: loadDataFromTemplate
  };

  /**
   * Retrieve the data by its key.
   *
   * @param {string}
   * @returns {angular.$q} Returns a $q promise where the resolve method receives the
   *   data and the reject method is called if the key isn't found.
   */
  function get(key) {
    return $q(function(resolve, reject) {
      if (DATA[key]) {
        resolve(DATA[key]);
      } else {
        reject();
      }
    });
  }

  /**
   * Finds any script tags inside the $element and loads the data from ones with
   * type ng-data.
   *
   * @param {angular.$element}
   */
  function loadDataFromTemplate($element) {
    angular.forEach($element.find('script'), function(scriptTag) {
      if (scriptTag.type == 'text/ng-data') {
        _setDataFromScriptTag(angular.element(scriptTag));
      }
    });
  }

  /**
   * Stores the data under the key.
   *
   * @private
   * @param {String} key
   * @param {Object} data
   */
  function _set(key, data) {
    DATA[key] = data;
  }

  /**
   * Takes a script tag and stores the data represented by it under a kay.
   *
   * @private
   * @param {angular.element} scriptTag
   */
  function _setDataFromScriptTag(scriptTag) {
    _set(scriptTag.attr('id'), JSON.parse(scriptTag.text()));
  }
}

module.exports = DataService;
