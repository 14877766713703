export default class UrlService {
  /**
   * Returns the colon prefixed value for a given url matcher.
   * getPathParam('/fonts/tags/:tag') for the url "/fonts/tags/something" returns 'something'
   */
  static getPathParam(matcher) {
    const matchParts = matcher.split('/');
    const urlParts = this.getWindowLocation().pathname.split('/');
    while (matchParts.length) {
      const matchPart = matchParts.shift();
      const urlPart = urlParts.shift();
      // If the match part is empty, then it's the root.
      if (matchPart === urlPart) {
        continue;
      }
      // If the match part starts with a colon, then it's a matcher.
      if (matchPart.indexOf(':') === 0) {
        return urlPart;
      }
      // If the match part doesn't start with a colon, then it's not a matcher.
      if (matchPart !== urlPart) {
        return;
      }
    }
  }

  /**
   * Returns the search value for the key.
   *
   * @param {String} key
   * @returns {String}
   */
  static getSearchParam(key) {
    return this.parseSearchParams(this.getWindowLocation().search)[key];
  }

  /**
   * Returns the parsed search portion of the url as an object.
   * @returns {{}}
   */
  static getSearchParams() {
    return this.parseSearchParams(this.getWindowLocation().search);
  }

  static getSerializedSearchParams(searchParams) {
    const searchParamsWithoutNullValues = Object.fromEntries(Object.entries(searchParams).filter(([, value]) => value !== null));
    return new URLSearchParams(searchParamsWithoutNullValues).toString();
  }

  /**
   * Gets the window location. This has its own method so it can be stubbed in tests.
   *
   * @returns {Object}
   */
  static getWindowLocation() {
    return window.location;
  }

  /**
   * Parses the search portion of a url and returns an object that
   * represents the search string.
   *
   * @param {String} searchString
   * @return {{}}
   */
  static parseSearchParams(searchString) {
    const rawKeyValuePairs = searchString.replace(/^\?/, '').split('&');
    return rawKeyValuePairs.sort().reduce((accumulator, item) => {
      const parts = item.split('=');
      if (parts[0]) {
        accumulator[parts[0]] = this._parseUrlValue(parts[1]);
      }

      return accumulator;
    }, {});
  }

  /**
   * Update the current path using history.pushState.
   *
   * @param {Object} stateObj
   * @param {String} title
   * @param {String} url
   */
  static pushState(stateObj, title, url) {
    history.pushState(stateObj, title, url);
  }

  /**
   * Remove the search param from the url.
   * @param {String} key
   */
  static removeSearchParam(key) {
    const searchParams = this.parseSearchParams(this.getWindowLocation().search);
    delete searchParams[key];

    this.setSearchParams(searchParams);
  }

  /**
   * Replace the existing with the search params passed in.
   * @param {{}} searchParams
   */
  static setSearchParams(searchParams) {
    const serialized = this.getSerializedSearchParams(searchParams);
    const location = this.getWindowLocation();
    let path = serialized.length === 0 ? location.pathname : '?' + serialized;
    if (location.hash) {
      path += location.hash;
    }
    history.replaceState(null, null, path);
  }

  /**
   * Parses a search string value and returns either an unencoded string
   * or a number, depending on the parsed value.
   *
   * @private
   * @param {String} value
   * @return {Integer|String}
   */
  static _parseUrlValue(value) {
    if (!value.match(/[a-z]/i) && parseInt(value)) {
      return parseInt(value);
    }

    return decodeURIComponent(value);
  }

  /**
   * Updates the existing search with search params passed in.
   * @param {{}} newParams
   */
  static updateSearchParams(newParams) {
    let searchParams = this.parseSearchParams(this.getWindowLocation().search);
    searchParams = {...searchParams, ...newParams};
    this.setSearchParams(searchParams);
  }

  /*
   * Updates the current path to the value passed
   * @param {String} newPath
   */
  static updatePath(newPath) {
    history.replaceState(null, null, newPath);
  }

  /**
   * Updates both the current path and search params.
   * @param {String} pathname
   * @param {{}} newParams
   */
  static updatePathAndSearchParams(pathname, newParams) {
    const location = this.getWindowLocation();
    let searchParams = this.parseSearchParams(location.search);
    searchParams = {...searchParams, ...newParams};
    const serialized = this.getSerializedSearchParams(searchParams);
    let path = serialized.length === 0 ? pathname : pathname + '?' + serialized;
    if (location.hash) {
      path += location.hash;
    }
    history.replaceState(null, null, path);
  }

  /**
   * Returns true if the current path begins with the path passed in.
   */
  static pathStartsWith(path) {
    return this.getWindowLocation().pathname.indexOf(path) === 0;
  }
}
